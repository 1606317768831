<template>
  <div class="scheduled-list-page">
    <!-- [筛选项] -->
    <kr-card :padding="[0, 0, 16, 0]" @rect:update="onTopCardRectUpdate">
      <a-form layout="inline" :form="queryParams">
        <div class="filter-condition-form">
          <a-form-item label="商品名称：">
            <a-input
              v-model.trim="queryParams.item_name"
              allowClear
              placeholder="请输入商品名称"
              style="width: 190px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <a-form-item label="商品编码：">
            <a-input
              v-model.trim="queryParams.item_id"
              allowClear
              placeholder="请输入商品编码"
              style="width: 190px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <a-form-item label="店铺名称：">
            <a-input
              v-model.trim="queryParams.shop_name"
              allowClear
              placeholder="请输入店铺名称"
              style="width: 190px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <a-form-item label="公司名称：">
            <a-input
              v-model.trim="queryParams.supplier_name"
              allowClear
              placeholder="请输入公司名称"
              style="width: 190px"
              @keypress.enter.native="reload"
            />
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              html-type="submit"
              :loading="listLoading"
              style="margin-right: 12px"
              @click="reload"
            >查询</a-button
            >
            <a-button
              type="primary"
              :loading="exportLoading"
              @click="exportListClick"
            >导出</a-button
            >
          </a-form-item>
        </div>
      </a-form>
    </kr-card>
    <!-- [列表] -->
    <a-table class="tablecl" :scroll="{ x: 1000,y: tableScrollY }" :rowKey="record => record.id" :columns="goodsColumns" :dataSource="list" :pagination="pagination" :loading="listLoading" :locale="{ emptyText: '暂无商品' }"  @change="paginationHandler">
      <template slot="goods_img" slot-scope="record">
       <my-image v-if="record.pict_url" :imgUrl="record.pict_url" />
      </template>
      <template slot="goods_id" slot-scope="record">{{record.item_id}}</template>
      <template slot="kuran_price" slot-scope="record">
        {{record.price ? ('￥'+ record.price) : '--'}}
      </template>
      <template slot="activity_price" slot-scope="record">
        {{record.activity_price ? ('￥'+ record.activity_price) : '--'}}
      </template>
      <template slot="commission_rate" slot-scope="record">
        {{record.commission_rate || record.commission_rate===0 ? (record.commission_rate / 100 +'%') : '--'}}
      </template>
      <template slot="exclusive_commission_rate" slot-scope="record">
        {{record.exclusive_commission_rate || record.exclusive_commission_rate===0 ? record.exclusive_commission_rate/100+'%' : '--'}}
      </template>
      <template slot="operation" slot-scope="record">
        <a-button type="primary" size="small" class="mr-10" @click="handleApprovalGoods(record)" >查看</a-button>
      </template>
    </a-table>

    <a-drawer
      title="商品详情"
      placement="right"
      width="800"
      @close="closeClick"
      :visible="createGoodsDrawerVisiable"
    ><audit-detail :step="3" :readOnly="true" v-on:closeClick="closeClick" :detailData="goodsRow" /></a-drawer>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import auditDetail from "@/pages/admin/merchant/dialog/auditDetail";
import {exportLiveSelectionGoodsList, getLiveSelectionGoodsList} from "@/service/merchant";

export default defineComponent({
  name: 'KrScheduledGoods',
  components: {
    auditDetail
  },
  methods: {
    paginationHandler(params) {
      if (params.pageSize !== this.queryParams.page_size) {
        this.queryParams.page = 1;
        this.pagination.current = 1;
        this.queryParams.page_size = params.pageSize;
      } else {
        this.pagination.current = params.current;
        this.queryParams.page = params.current;
      }
      this.reload(false);
    }
  },
  setup(_, ctx) {
    const createGoodsDrawerVisiable = ref(false);
    const goodsRow = ref({})
    const handleApprovalGoods = (row) => {
      createGoodsDrawerVisiable.value = true;
      goodsRow.value = row;
    };
    const windowHeight = document.documentElement.clientHeight || 1000;
    const tableScrollY = ref(windowHeight - 350);
    const onTopCardRectUpdate = (rect) => {
      tableScrollY.value= windowHeight - 250 - rect.height;
    };
    // 商品列表
    const goodsColumns = [
      {
        title: "商品图片",
        fixed: 'left',
        width: 100,
        scopedSlots: { customRender: "goods_img" },
      },
      {
        title: "商品编码",
        fixed: 'left',
        width: 190,
        scopedSlots: { customRender: "goods_id" },
      },
      {
        title: "商品名称",
        dataIndex: "item_name",
        width: 200,
        ellipsis: true,
        align: "left",
        customRender: (text)=> {
          return text || '--'
        },
      },
      {
        title: "销售价",
        scopedSlots: { customRender: "kuran_price" },
        width: 160,
        align: "center",
      },
      {
        title: "活动价",
        scopedSlots: { customRender: "activity_price" },
        width: 160,
        align: "center",
      },
      {
        title: "佣金比例",
        scopedSlots: { customRender: "commission_rate" },
        width: 100,
        align: "center",
      },
      {
        title: "专属佣金比例",
        scopedSlots: { customRender: "exclusive_commission_rate" },
        width: 120,
        align: "center",
      },
      {
        title: "店铺名称",
        dataIndex: "shop_name",
        width: 200,
        ellipsis: true,
        align: "left",
        customRender: (text)=> {
          return text || '--'
        },
      },
      {
        title: "公司名称",
        dataIndex: "supplier",
        width: 200,
        ellipsis: true,
        align: "left",
        customRender: (text)=> {
          return text || '--'
        },
      },
      {
        title: "操作",
        align: "center",
        fixed: 'right',
        width: 100,
        scopedSlots: { customRender: "operation" },
      },
    ];

    const list = ref([]);
    const pagination = ref( {
      size: 'small',
      current: 1,
      pageSize: 10,
      pageSizeOptions: ["10", "20", "30", "40", "50"],
      showTotal: (total) =>
        `共 ${total} 条记录 第 ${pagination.value.current}/${Math.ceil(
          total / pagination.value.pageSize
          )} 页`,
      showQuickJumper: true,
      showSizeChanger: true,
      onShowSizeChange: (current, pageSize) =>
        (pagination.value.pageSize = pageSize),
    })
    const queryParams = ref({
      page: 1,
      page_size: 10,
      item_name: '',
      shop_name:'',
      supplier_name:'',
      check_status: undefined,
      item_id: ''
    });

    const listLoading = ref(false)
    const getGoodsList = async () => {

      listLoading.value = true;
      const { err, res } = await getLiveSelectionGoodsList(ctx.root.$router.currentRoute.query.event_id, {
        ...queryParams.value,
        event_id: ctx.root.$router.currentRoute.query.event_id,
      });
      listLoading.value = false;
      if (!err) {
        if (res.success) {
          const one_pagination = { ...pagination.value };
          list.value = res.data.results;
          one_pagination.total = res.data.count;
          pagination.value = one_pagination;
        }
      }
    };

    const reload = async (clean = true) => {
      if (clean) {
        pagination.value.current = 1;
        queryParams.value.page = 1;
      }

      await getGoodsList();
    };

    const closeClick = ()=> {
      createGoodsDrawerVisiable.value=false;
    };

    // 导出列表处理
   const exportLoading =ref(false)
    const exportListClick = async() => {
     exportLoading.value = true;
      const { res } = await exportLiveSelectionGoodsList(
        ctx.root.$router.currentRoute.query.event_id, {
      ...queryParams.value,
          event_id: ctx.root.$router.currentRoute.query.event_id,
      });
      exportLoading.value = false;
      if (res && res.success) {
        location.href = res.data.detail_url;
        ctx.root.$message.success('导出成功');
      }else{
        ctx.root.$message.error('导出失败');
      }
    };
    return {
      exportLoading,
      exportListClick,
      closeClick,
      onTopCardRectUpdate,
      goodsRow,
      handleApprovalGoods,
      createGoodsDrawerVisiable,
      pagination,
      listLoading,
      goodsColumns,
      tableScrollY,
      list,
      queryParams,
      reload
    };
  },
  created() {
    this.reload();
  },
});
</script>

<style lang="less">
.scheduled-list-page {
  .ant-table-scroll .ant-table-body {
    //样式穿透
    overflow-x: scroll !important;
  }
  display: flex;
  flex-direction: column;
  .mr-10 {
    margin-right: 10px;
  }
  .ant-table-tbody > tr {
    height: 87px !important;
  }
  /deep/ .ant-table-scroll .ant-table-body {
    //样式穿透
    overflow-x: scroll !important;
  }
  /deep/.ant-table-tbody > tr {
    height: 87px !important;
  }
}
</style>

<style lang="less" scoped>
/deep/ .ant-table-scroll .ant-table-body {
  //样式穿透
  overflow-x: scroll !important;
}
.ant-table-scroll .ant-table-body {
  //样式穿透
  overflow-x: scroll !important;
}

</style>
